import * as React from 'react';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { withRouter } from 'react-router-dom';
import type { Entities } from '@inwink/entities/entities';
import { stringify } from '@inwink/utils/querystring';
import { getPropertyValue } from '@inwink/utils/methods/getpropertyvalue';
import { EntityTemplateContextConsumer, IEntityTemplateContext } from '../entities/entitytemplatecontext';
import type { ItemTemplateProps } from './itemtemplate.props';

export interface ITagsListProps extends ItemTemplateProps {
    showOnlyLabels: boolean;
    i18nHelper?: Entities.i18nHelper;
    history?: any;
}

@withI18nHelper()
class TagsListComponent extends React.Component<ITagsListProps, any> {
    tagClicked(fieldname: string, tag) {
        const entityKind = this.props.datacontext && this.props.datacontext.entityKind
            ? this.props.datacontext.entityKind : this.props.page.context.entityKind;

        const args = {};
        args[fieldname] = tag.key;
        if (entityKind === "Session") {
            this.props.history.push({
                pathname: this.props.urlservice.pageUrl(`sessions`),
                search: "?" + stringify(args)
            });
        } else if (entityKind === "Exhibitor") {
            this.props.history.push({
                pathname: this.props.urlservice.pageUrl(`partners`),
                search: "?" + stringify(args)
            });
        }
    }

    tagsComponentsFor(i18n, fieldname: string, tgs: any[], fieldTemplate: Entities.IEntityFieldTemplate) {
        let tags = tgs;
        if (typeof tags === "string") {
            tags = [tags];
        }
        const entityKind = this.props.datacontext && this.props.datacontext.entityKind
            ? this.props.datacontext.entityKind : this.props.page?.context?.entityKind;

        const clickable = ((entityKind === "Session") || (entityKind === "Exhibitor"))
            && (!this.props.template.doNotApplyLink) && (!this.props.showOnlyLabels);

        return tags.filter((t) => {
            return t != null && t !== undefined && t !== ''
                && (typeof t === "string" && fieldTemplate && fieldTemplate.valuesList
                    && fieldTemplate.valuesList.filter((v) => v.key === t)[0]);
        }).map((t) => {
            let item = t;
            if (typeof t === "string") {
                let fieldvalue = "";
                const fieldval = fieldTemplate.valuesList.filter((v) => v.key === t)[0];
                fieldvalue = i18n.translateBag(fieldval.labels);
                item = {
                    key: t,
                    label: fieldvalue
                };
            }
            return item;
        }).sort((a, b) => {
            if (fieldTemplate.valuesListSort) {
                if (fieldTemplate.valuesListSort === 'az') {
                    if (a.label > b.label) {
                        return 1;
                    }
                    if (a.label < b.label) {
                        return -1;
                    }
                    return 0;
                } if (fieldTemplate.valuesListSort === 'za') {
                    if (a.label < b.label) {
                        return 1;
                    }
                    if (a.label > b.label) {
                        return -1;
                    }
                    return 0;
                }
            }
            const aIx = fieldTemplate.valuesList.findIndex((v) => v.key === a.key);
            const bIx = fieldTemplate.valuesList.findIndex((v) => v.key === b.key);
            if (aIx < bIx) {
                return -1;
            } if (aIx > bIx) {
                return 1;
            }
            return 0;
        }).map((t) => {
            if (this.props.showOnlyLabels) {
                return <span className="label">{t.label}</span>;
            }
            let onclick;
            const classes: string[] = ["tag bloc-lightbg keywordbubble"];
            classes.push("tag bloc-lightbg keywordbubble");
            if (clickable) {
                classes.push("clickable");
                onclick = () => this.tagClicked(fieldname, t);
            }
            return <div key={t.key} className={classes.join(" ")} onClick={onclick}>{t.label}</div>;
        });
    }

    renderTags(context: IEntityTemplateContext) {
        let items;
        let tags;
        const i18n = this.props.i18nHelper;
        const templatefield = this.props.template.fields && this.props.template.fields[0];
        const field = templatefield && templatefield.name;
        if (!field) return null;
        if (templatefield.fromcontext) {
            items = getPropertyValue(this.props.datacontext, field);
        } else if (this.props.data) {
            items = getPropertyValue(this.props.data, field);
        } else if (this.props.page && this.props.page.context && this.props.page.context.entity) {
            const entity = this.props.page.context.entity;
            items = getPropertyValue(entity, field);
        }

        if (items && items.length) {
            const template = (context?.fieldTemplate) || (this.props.page?.context?.fieldtemplate);

            if (typeof items === "string") {
                items = [items];
            }

            if (template) {
                const fieldKey = field.indexOf('.') > -1 ? field.split('.').pop() : field;
                const fieldTemplate = template.template.fields.filter((f) => f.key === fieldKey)[0];
                tags = this.tagsComponentsFor(i18n, fieldKey, items, fieldTemplate);
            }
        } else {
            return null;
        }

        let title;
        if (this.props.template && this.props.template.title) {
            title = <h4 className="bloc-accent template-bloc-title">{i18n.translateBag(this.props.template.title)}</h4>;
        }

        if (this.props.showOnlyLabels) {
            return <span className={"entityfieldlabel"
                + (this.props.template.className ? " " + this.props.template.className : "")}
            >
                <span className="entityfieldlabel-items">
                    {tags}
                </span>
            </span>;
        }
        return <section className={"tagslist" + (this.props.template.className ? " " + this.props.template.className : "")}>
            {title}
            <div className="tagslist-items">
                {tags}
            </div>
        </section>;
    }

    render() {
        return <EntityTemplateContextConsumer>
            {(ctx) => this.renderTags(ctx)}
        </EntityTemplateContextConsumer>;
    }
}

export const TagsList: new (props: ITagsListProps)
=> React.Component<ITagsListProps, any> = withRouter(TagsListComponent as any) as any;
