import * as React from 'react';
import { ItemTemplateProps } from './itemtemplate.props';
import { AppLink } from '../applink';
import { AppTextLabel, DynLabel } from '../../services/i18nservice';
import '../sociallinks.less';
import './entityshare.less';

export class EntityShare extends React.PureComponent<ItemTemplateProps, any> {
    render() {
        var label;
        if (this.props.template.title){
            label = <DynLabel component="h4" className="bloc-accent" i18n={this.props.template.title} />
        }else{
            label = <AppTextLabel component="h4" className="bloc-accent" i18n="actions.share" />;
        }
        var links = [];
        var conf = this.props.template.properties;

        if (!conf || !conf.disableFacebook) {
            links.push(<AppLink key="facebook" link={{ share: "facebook"}} className="sociallink clickable"><i className="icon inwink-facebook-logo"></i></AppLink>)
        }
        if (!conf || !conf.disableTwitter) {
            links.push(<AppLink key="twitter" link={{ share: "twitter"}} className="sociallink clickable"><i className="icon inwink-twitter-logo"></i></AppLink>)
        }
        if (!conf || !conf.disableLinkedin) {
            links.push(<AppLink  key="linkedin" link={{ share: "linkedin"}} className="sociallink clickable"><i className="icon inwink-linkedin-big-logo"></i></AppLink>)
        }
        
        return <div className="entity-share">
            {label}    
            <div className="sociallinks">
                {links}
            </div>
        </div>
    }
}