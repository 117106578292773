import * as React from 'react';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { ContentStyle } from '../../contentstyle';
import { EntityTemplateContextProvider } from '../../entities/entitytemplatecontext';
import { ItemTemplate } from '../../templates/itemtemplate';
import { ItemTemplateProvider } from '../../templates/itemtemplateprovider';
import { DynamicBloc, IDynamicBlocWithProps } from '../common';
import { BlocTitle, BlocContent } from "../common.title";
import { registerBloc } from '../blocscatalog';
import { BlocEntityDetailActionBar } from './bloc.entitydetail.actionbar';
import { States } from '../../../services/services';
import { registerEntityDetailParts } from '../../templates/entitydetail';
import { IBlocEntityDetailState, EntityDetailContext } from './bloc.entitydetail.state';

import './bloc.entitydetail.less';
import { getPropertyValue } from '@inwink/utils';

registerEntityDetailParts();

export interface IBlocEntityDetailV2Props
    extends IDynamicBlocWithProps<IBlocEntityDetailState, IBlocEntityDetailV2Properties> {

}

interface IBlocEntityDetailV2Properties extends VisualTheme.IEntityDetailProperties {
    dataPath?: string;
}

export function blocEntityDetailV2Data(
    page: States.ICurrentPageState,
    blocdata: IBlocEntityDetailState,
    bloc: VisualTheme.IBlocContentTemplateBase,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    state: States.IAppState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    dispatch: (action) => void
) {
    // eslint-disable-next-line no-param-reassign
    blocdata.footerTheme = bloc.properties?.footerTheme;
}

export class BlocEntityDetailV2 extends React.Component<IBlocEntityDetailV2Props, any> {
    constructor(props: IBlocEntityDetailV2Props) {
        super(props);
        this.state = {
            entitycontext: {
                addActions: (actions) => {
                    props.update({ actions: actions });
                }
            },
            contentTemplateProvider: {
                getTemplate: (templatename: string) => {
                    const properties = this.props.template?.properties;
                    const content = properties && (properties.template || properties.content);
                    return content && content[templatename];
                },
                getTemplateProperties: () => {
                    const properties = this.props.template?.properties;
                    return properties && (properties as any).templateProperties;
                }
            }
        };
    }

    onFieldNavigation = (fieldname, val) => {
        const entityKind = this.props.datacontext && this.props.datacontext.entityKind;
        
        if (entityKind === "Session") {
            this.props.history?.push(this.props.urlservice.pageUrl(`sessions?${fieldname}=${encodeURIComponent(val)}`));
        } else if (entityKind === "Exhibitor") {
            this.props.history?.push(this.props.urlservice.pageUrl(`partners?${fieldname}=${encodeURIComponent(val)}`));
        }
    };

    patchHeader = (newtemplate) => {
        let patch = newtemplate;

        if (this.props.template.properties?.header) {
            patch = Object.assign({}, this.props.template.properties, { header: patch });
        }

        patch = Object.assign({}, this.props.template, { properties: patch });
        this.props.patchContent(patch);
    };

    patchTemplate = (newtemplate) => {
        let patch = newtemplate;
        if ((this.props.template.properties?.template as any)?.template) {
            patch = Object.assign({}, this.props.template, { template: patch });
        }
        if ((this.props.template.properties?.content as any)?.template) {
            patch = Object.assign({}, this.props.template.properties.content, { template: patch });
        }

        patch = Object.assign({}, this.props.template.properties, { template: patch });

        patch = Object.assign({}, this.props.template, { properties: patch });
        this.props.patchContent(patch);
    };

    render() {
        const template = this.props.template;
        let content;
        let itemStyle;
        const properties = this.props.template.properties;
        if (!this.props.page.context) {
            return <DynamicBloc {...this.props}>Loading...</DynamicBloc>;
        }

        if (template) {
            if (template.customCSS) {
                itemStyle = <ContentStyle
                    css={template.customCSS}
                    blocid={this.props.bloctemplate.id}
                    contentid={this.props.template.id}
                    theme={this.props.theme}
                />;
            }
        }

        let header = <BlocTitle {...this.props} className="bloc-header" />;
        let entity = this.props.page.context?.entity;

        if (entity && this.props.template?.properties?.dataPath) {
            entity = getPropertyValue(entity, this.props.template?.properties?.dataPath);
        }

        if (entity) {
            if (properties?.header) {
                header = <ItemTemplateProvider provider={this.state.headerTemplateProvider}>
                    <div className="bloc-header">
                        <ItemTemplate
                            {...this.props}
                            contentEditable={this.props.editable}
                            page={this.props.page}
                            user={this.props.user.currentUser}
                            template={properties.header}
                            fieldtemplate={this.props.page.context ? this.props.page.context.fieldtemplate : null}
                            data={entity}
                            onFieldNavigation={this.onFieldNavigation}
                            onEdit={this.patchHeader}
                        />
                    </div>
                </ItemTemplateProvider>;
            }

            if (properties?.template || properties?.content) {
                content = <ItemTemplateProvider provider={this.state.contentTemplateProvider}>
                    <BlocContent {...this.props}>
                        <ItemTemplate
                            {...this.props}
                            contentEditable={this.props.editable}
                            className="bloc-content"
                            page={this.props.page}
                            user={this.props.user.currentUser}
                            template={properties.template || properties.content}
                            fieldtemplate={this.props.page.context ? this.props.page.context.fieldtemplate : null}
                            data={entity}
                            onFieldNavigation={this.onFieldNavigation}
                            onEdit={this.patchTemplate}
                        />
                    </BlocContent>
                </ItemTemplateProvider>;
            }
        }

        return <EntityTemplateContextProvider fieldTemplate={this.props.page.context && this.props.page.context.fieldtemplate}>
            <EntityDetailContext.Provider value={this.state.entitycontext}>
                <DynamicBloc {...this.props} className={"dynamic-bloc-entitydetail " + (this.props.className || "")}>
                    {itemStyle}
                    {header}
                    {content}
                </DynamicBloc>
            </EntityDetailContext.Provider>
        </EntityTemplateContextProvider>;
    }
}

registerBloc("entitydetailv2", {
    component: BlocEntityDetailV2,
    footerComponent: BlocEntityDetailActionBar,
    datainit: blocEntityDetailV2Data
});
