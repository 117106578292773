import * as React from 'react';

export interface IEntityDetailAction {
    key: string;
    label? : string;
    icon?: string;
    callback : (arg?) => any;
}

export interface IEntityDetailContext {
    addActions(actions: IEntityDetailAction[])
}

export interface IBlocEntityDetailState {
    footerTheme : string;
    actions : IEntityDetailAction[];
}

export const EntityDetailContext = React.createContext<IEntityDetailContext>(null);
