import * as React from 'react';
import type {ItemTemplateProps} from './itemtemplate.props';
import {SocialLinks} from '../sociallinks';

export interface SocialLinksTemplateProps extends ItemTemplateProps {
    showMail : boolean;
}
export class SocialLinksTemplate extends React.Component<SocialLinksTemplateProps, any> {
    render(){
        let entity = this.props.data ? this.props.data : (this.props.page && this.props.page.context && this.props.page.context.entity ? this.props.page.context.entity : null);
        let showMail = this.props.showMail != undefined ? this.props.showMail : entity && entity.mail != undefined;
        let showWebsite = this.props.template && this.props.template.properties && this.props.template.properties.showWebsite != undefined ? this.props.template.properties.showWebsite : true;
        
        return <SocialLinks showMail={ showMail } entity={ entity } showWebsite={ showWebsite } />
    }
}
