import * as React from 'react';
import { AppTextLabel } from '@inwink/i18n/apptextlabel';
import { Stagger } from '@inwink/animation/wrappers/stagger';
import { Transitions } from '@inwink/animation/transitions';
import { IDynamicBlocProps } from '../common';
import { IBlocEntityDetailState } from './bloc.entitydetail.state';

export class BlocEntityDetailActionBar extends React.Component<IDynamicBlocProps<IBlocEntityDetailState>, any> {
    componentDidMount() {
        setTimeout(() => {
            this.setState({ ready: true });
        }, 160);
    }

    render() {
        if (!this.props.blocState || !this.props.blocState.actions || !this.props.blocState.actions.length) return null;

        let actions = [];
        if (this.state && this.state.ready) {
            actions = this.props.blocState.actions.map((act) => {
                return <button type="button" key={act.key} onClick={act.callback}>
                    {act.icon ? <i className={act.icon} /> : null} <AppTextLabel className="label" i18n={act.label} />
                </button>;
            });
        }

        return <div
            className={"dynamic-bloc-entitydetail-footer bloctheme "
            + (this.props.blocState.footerTheme ? "bloctheme-" + this.props.blocState.footerTheme + " " : "")
            + (this.props.className || "")}
        >
            <Stagger className="entityactions" appear delay={200} max={2000} items={itemTransition}>
                {actions}
            </Stagger>
        </div>;
    }
}

const itemTransition = {
    // in : true,
    timeout: 300,
    appear: true,
    enter: Transitions.enter.fadeFromBottom
};
