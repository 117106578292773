import * as React from 'react';
import { registerItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';
import { EntityShare } from './entityshare';
import { SocialLinksTemplate } from './sociallinks';
import { TagsList } from './tagslist';

export function registerEntityDetailParts() {
    registerItemTemplatePart("fieldlabel", (props) => React.createElement(TagsList, { ...props, showOnlyLabels: true }));
    registerItemTemplatePart("tags", TagsList);
    registerItemTemplatePart("entityshare", EntityShare);
    registerItemTemplatePart("sociallinks", SocialLinksTemplate);
}